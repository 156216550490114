import request from "@/utils/request";

// ChargeRule
export function chargeClientList(params) {
  return request({ url: `/charge_clients/`, method: "get", params });
}

export function chargeClientSetRule(data) {
  return request({ url: `/charge_clients/${data.id}/set_rule/`, method: "post", data });
}

export function chargeRuleList(params) {
  return request({ url: `/charge_rules/`, method: "get", params });
}

export function chargeRuleUpdate(data) {
  return request({ url: `/charge_rules/${data.id}/`, method: "put", data });
}

export function chargeRuleExport(params) {
  return request({
    url: "/charge_rules/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function chargeRuleTemplate(params) {
  return request({
    url: "/charge_rules/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function chargeRuleImport(data) {
  return request({
    url: `/charge_rules/import_data/`,
    method: "post",
    data,
  });
}

// StockInChargeRecord
export function stockInChargeRecordList(params) {
  return request({ url: `/stock_in_charge_records/`, method: "get", params });
}

// StockOutChargeRecord
export function stockOutChargeRecordList(params) {
  return request({ url: `/stock_out_charge_records/`, method: "get", params });
}

// StorageChargeMaterial
export function storageChargeMaterialList(params) {
  return request({ url: `/storage_charge_records/`, method: "get", params });
}

// StockInExpenseRecord
export function stockInExpenseRecordList(params) {
  return request({ url: `/stock_in_expense_records/`, method: "get", params });
}

export function stockInExpenseRecordCreate(data) {
  return request({ url: `/stock_in_expense_records/`, method: "post", data });
}

export function stockInExpenseRecordVoid(data) {
  return request({ url: `/stock_in_expense_records/${data.id}/void/`, method: "post", data });
}

export function stockInExpenseExport(params) {
  return request({
    url: "/stock_in_expense_records/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// StockOutExpenseRecord
export function stockOutExpenseRecordList(params) {
  return request({ url: `/stock_out_expense_records/`, method: "get", params });
}

export function stockOutExpenseRecordCreate(data) {
  return request({ url: `/stock_out_expense_records/`, method: "post", data });
}

export function stockOutExpenseRecordVoid(data) {
  return request({ url: `/stock_out_expense_records/${data.id}/void/`, method: "post", data });
}

export function stockOutExpenseExport(params) {
  return request({
    url: "/stock_out_expense_records/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// Report
export function chargeReportList(params) {
  return request({ url: `/statistics/charge_reports/`, method: "get", params });
}

export function chargeReportExport(params) {
  return request({ url: `/statistics/charge_reports/export/`, method: "get", responseType: "blob", params });
}

// Detail
export function chargeDetailList(params) {
  return request({ url: `/charge_details/${params.id}/details/`, method: "get", params });
}

export function chargeDetailTotal(params) {
  return request({ url: `/charge_details/${params.id}/total_amount/`, method: "get", params });
}

export function chargeDetailExport(params) {
  return request({
    url: `/charge_details/${params.id}/export/`,
    responseType: "blob",
    method: "get",
    params,
  });
}
