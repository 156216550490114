import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("仓库"),
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("货主"),
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("入库通知单号"),
    dataIndex: "receipt_order_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("收费项目"),
    dataIndex: "charge_item",
    width: 120,
    ellipsis: true,
 },
  {
    title: T("费用金额(元)"),
    dataIndex: "total_amount",
    width: 100,
    ellipsis: true,
 },
  {
    title: T("状态"),
    dataIndex: "status",
    width: 100,
    ellipsis: true,
    customRender: (value, item) => (item.is_void ? T("已作废") : T("已完成")),
  },
  {
    title: T("创建人"),
    dataIndex: "creator_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    width: 100,
  },
];
