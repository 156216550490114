<template>
  <div>
    <a-row gutter="16">
      <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <a-range-picker @change="changeDate" />
      </a-col>
      <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
          <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <client-select v-model="searchForm.client" :placeholder="$t('货主')" @change="search" />
      </a-col>
      <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
        <a-input v-model="searchForm.search" :placeholder="$t(单号, 往来单位编号, 名称)" allowClear @pressEnter="search" />
      </a-col>
      <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
        <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
      </a-col>
      <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
        <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
      </a-col>

      <div style="margin-bottom: 12px; float: right">
        <a-button type="primary" icon="plus" style="margin: 0 8px" @click="visible = true">{{ $t("新增费用") }}</a-button>
      </div>
    </a-row>

    <a-row>
      <a-table
        size="small"
        :columns="columns"
        :dataSource="items"
        :loading="loading"
        :pagination="pagination"
        :scroll="{ x: 1020 }"
        @change="tableChange"
      >
        <div slot="action" slot-scope="value, item">
          <a-button-group>
            <a-popconfirm :title="$t('确定作废吗?')" @confirm="voidData(item)">
              <a-button type="danger" icon="delete" size="small">{{ $t("作废") }}</a-button>
            </a-popconfirm>
          </a-button-group>
        </div>
      </a-table>
    </a-row>

    <form-modal v-model="visible" @create="create" />
  </div>
</template>

<script>
import { stockInExpenseRecordList, stockInExpenseRecordVoid, stockInExpenseExport } from "@/api/charge";
import { warehouseOption } from "@/api/option";
import columns from "./columns.js";
import { exportExcel } from "@/utils/excel";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
    FormModal: () => import("./FormModal.vue"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      warehouseItems: [],
      visible: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.searchForm.warehouse = this.currentWarehouse;
      });
    },
    list() {
      this.loading = true;
      stockInExpenseRecordList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    changeDate(date) {
      let startDate = date[0];
      let endDate = date[1];

      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
    create(item) {
      this.items.splice(0, 0, item);
    },
    voidData(item) {
      stockInExpenseRecordVoid({ id: item.id }).then(() => {
        this.$message.success(this.$t("作废成功"));
        this.list();
      });
    },
    exportExcel() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      stockInExpenseExport(searchForm)
        .then((resp) => {
          exportExcel(resp, this.$t("入库其他费用"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
